var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"phonemazwrapper"},[_c('span',{staticClass:"country-label"},[_vm._v(_vm._s(_vm.$t('Landnummer')))]),_c('span',{staticClass:"number-label"},[_vm._v(_vm._s(_vm.$t('Telefoonnummer')))]),_c('div',{staticClass:"elementwrapper"},[_c('MazPhoneNumberInput',{class:{invalid: !_vm.isValid && _vm.touched},attrs:{"noValidation":"","translations":_vm.translations ? _vm.translations : {},"default-country-code":_vm.countryCode,"preferred-countries":[
            'BE',
            'DE',
            'FR',
            'GB',
            'LU',
            'NL'
        ]},on:{"update":_vm.updatePhoneNumber,"blur":_vm.fieldOnBlur},model:{value:(_vm.mutatableVariable),callback:function ($$v) {_vm.mutatableVariable=$$v},expression:"mutatableVariable"}}),_c('span',{staticClass:"phone-number-error",style:({visibility: !_vm.isValid && _vm.touched ? 'visible' : 'hidden'})},[_vm._v(" "+_vm._s(_vm.$t('Telefoonnummer invullen is verplicht.'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Vul een geldig telefoonnummer in.'))+" ")]),(!_vm.isValid && _vm.touched)?_c('div',{staticClass:"bumper"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }