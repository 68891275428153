import { render, staticRenderFns } from "./login-modal.component.vue?vue&type=template&id=0dff1e8c&scoped=true&"
import script from "./login-modal.component.ts?vue&type=script&lang=ts&"
export * from "./login-modal.component.ts?vue&type=script&lang=ts&"
import style0 from "./login-modal.component.scss?vue&type=style&index=0&id=0dff1e8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dff1e8c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VIcon})
