import { render, staticRenderFns } from "./datepicker.component.vue?vue&type=template&id=3f83707d&scoped=true&"
import script from "./datepicker.component.ts?vue&type=script&lang=ts&"
export * from "./datepicker.component.ts?vue&type=script&lang=ts&"
import style0 from "./datepicker.component.scss?vue&type=style&index=0&id=3f83707d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f83707d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VBadge,VBtn,VCard,VDatePicker,VIcon,VMenu,VSlideGroup,VSlideItem})
